import { GameEvent } from 'common/infrastructure/modules/game';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';

export const ResultsViewNavigationTimeout = 9;

export const useDispatchDisplayRoutes = (lastGameEvent: GameEvent | null) => {
  const navigate = useNavigate();

  useEffect(() => {
    console.log(lastGameEvent);
    // TODO: WAG-1096, WAG-1020 Adjust the the routing to the new play type
    // if (!lastGameEvent) {
    //   navigate(DisplayNavigationRoutes.WaitingRoom, { replace: true });
    //   return;
    // }

    // switch (lastGameEvent.type) {
    //   case GameEventType.CANCELED:
    //     navigate(DisplayNavigationRoutes.WaitingRoom, { replace: true });
    //     break;

    //   case GameEventType.START:
    //   case GameEventType.HOLE_CHANGED:
    //   case GameEventType.SHOT:
    //     navigate(DisplayNavigationRoutes.ContestInProgress, { replace: true });
    //     break;

    //   case GameEventType.END:
    //     const now = new Date();
    //     const lastGameEventTimestamp = new Date(lastGameEvent.timestamp);

    //     if (differenceInSeconds(now, lastGameEventTimestamp) > ResultsViewNavigationTimeout) {
    //       navigate(DisplayNavigationRoutes.WaitingRoom, { replace: true });
    //       break;
    //     }

    //     navigate(DisplayNavigationRoutes.Results, { replace: true });
    //     break;
    // }
  }, [lastGameEvent, navigate]);
};
